import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.map.js";
import ModalMixin from "./Modal.mixin";
import LoadingSpinner from "@/components/Loading/Spinner";
import Focus from "@/components/Focus";
export { ModalMixin };
export default {
  components: {
    LoadingSpinner,
    Focus
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    width: {
      default: 420
    },
    actions: {
      type: Array,
      default: () => {
        return [];
      }
    },
    scrollOutside: {
      type: Boolean,
      default: false
    },
    titlePosition: {
      type: String,
      default: "left"
    },
    advanced: {
      type: Boolean,
      default: false
    },
    wide: {
      type: Boolean,
      default: true
    },
    rightDark: {
      type: Boolean,
      default: false
    },
    zIndex: {
      type: Number,
      default: 500
    },
    noClose: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isOpened: false,
      focusKey: 1
    };
  },
  mounted() {},
  computed: {
    visible() {
      return this.$store.state.modals.length && this.$store.state.modals.findIndex(i => i.id === this.uid) === this.$store.state.modals.length - 1;
    },
    uid() {
      return this._uid;
    },
    actionsComputed() {
      return this.actions.map(action => {
        const presets = {
          default: {
            class: "btn-blue",
            action: () => {}
          },
          cancel: {
            class: "btn-outline",
            text: "Отмена",
            action: ({
              close
            }) => {
              close();
            }
          }
        };
        if (typeof action === "string") {
          return presets[action] || presets.default;
        }
        return {
          ...presets.default,
          ...action
        };
      });
    }
  },
  methods: {
    onAction(action) {
      if (action.action) {
        action.action({
          close: () => {
            this.close();
          }
        });
      }
    },
    async open({
      exclusive = false
    } = {}) {
      if (exclusive) {
        this.$store.state.modals.forEach(i => i.id !== this.uid && i.close({
          immediately: true
        }));
      }
      await this.$nextTick();
      this.$store.state.modals.push({
        id: this.uid,
        close: opts => {
          this.close(opts);
        }
      });
      this.isOpened = true;
      this.$emit("open");
      this.focusKey++;
    },
    close({
      immediately = false
    } = {}) {
      if (!this.noClose) {
        this.$emit("close");
        this.$set(this.$store.state, "modals", this.$store.state.modals.filter(i => i.id !== this.uid));
        if (immediately) {
          this.isOpened = false;
        } else {
          setTimeout(() => {
            // this.isOpened = false; @TODO fix RML
          }, 300);
        }
      }
    }
  },
  beforeDestroy() {
    this.$set(this.$store.state, "modals", this.$store.state.modals.filter(i => i.id !== this.uid));
  }
};