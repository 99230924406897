//* /MP/salesfinder/src/store/keyword.js

import Vue from "vue";
import { mapFilters } from "@/utils/newModule";

export default {
    namespaced: true,
    actions: {
        //*===================================
        async getKeywordLimit(context, { fake }) {
            const { data } = await Vue.api.get("/getKeywordLimit", {
                params: {
                    fake,
                },
            });
            return data;
        },

        //*===============================================================
        async getKeyword(context, { k_id, mp, date, date2, fbs, kwd }) {
            const { data } = await Vue.api.get("/getKeyword", {
                params: {
                    mp,
                    date,
                    date2,
                    k_id,
                    fbs,
                    kwd,
                },
            });
            return data;
        },

        async getKeywordByName(context, { query, mp }) {
            const { data } = await Vue.api.get("/getKeywordByName", {
                params: {
                    query,
                    mp,
                },
            });
            return data;
        },

        async keywordOverviewAll(context, queryParams) {
            const { data } = await Vue.api.get("/keywordOverviewAll", {
                params: {
                    ...queryParams,
                },
            });
            return data;
        },
        /*
        async keywordOverviewAll(context, { mp, k_id, date, date2, fbs, type, snap, kwd }) {
            const { data } = await Vue.api.get("/keywordOverviewAll", {
                params: {
                    mp,
                    k_id,
                    date,
                    date2,
                    fbs,
                    type, snap, kwd,
                    // like _CH_DROP_CACHE_
                    ...queryParams,
                },
            });
            return data;
        },
        */

        async getKeywordProducts(context, queryParams) {
            const { mp, k_id, date, date2 } = queryParams;

            //console.log('48: getKeywordProducts.queryParams=',queryParams)

            const { data } = await Vue.api.get("/getKeywordProducts", {
                params: {
                    mp,
                    k_id,
                    date,
                    date2,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },

        async getKeywordBrands(context, queryParams) {
            const { mp, k_id, date, date2 } = queryParams;
            const { data } = await Vue.api.get("/getKeywordBrands", {
                params: {
                    mp,
                    k_id,
                    date,
                    date2,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },

        async getKeywordSellers(context, queryParams) {
            const { mp, k_id, date, date2 } = queryParams;
            const { data } = await Vue.api.get("/getKeywordSellers", {
                params: {
                    mp,
                    k_id,
                    date,
                    date2,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },

        async getKeywordLike(context, queryParams) {
            const { mp, k_id, date, date2 } = queryParams;
            const { data } = await Vue.api.get("/getKeywordLike", {
                params: {
                    mp,
                    k_id,
                    date,
                    date2,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },

        async getKeywordDynamic(
            context,
            {
                page = 1,
                page_size = 10,
                skip = 0,
                mp,
                trends = null,
                trendsTop100 = null,
                // date,
                // date2,
                filters,
                sort,
                sort_dir,
            }
        ) {
            skip = (page - 1) * page_size;
            const body = {
                marketPlace: mp,
                filter: filters,
                take: page_size,
                pageSize: page_size,
                page,
                skip,
            };
            if (sort) {
                body.sort = sort;
            }
            if (sort_dir) {
                body.sort_dir = sort_dir;
            }
            if (body.filter) {
                body.filter = mapFilters(body.filter);
            }

            if (trends) {
                body.fromMonth = trends.fromMonth;
                body.toMonth = trends.toMonth;
                body.trend = trends.trend;
                body.percent = trends.percent;
                body.showLess = trends.showLess;
                body.filter = {};
                body.filter.keyword = trends.keyword;
            }

            // body.filter = {
            //     ...body.filter,
            //     date,
            //     date2,
            // };
            // if (body.filter?.date_shows) {
            //     body.filter.shows = body.filter.date_shows;
            //     delete body.filter.date_shows;
            // }
            if (body.filter) {
                const shows = [];
                Object.keys(body.filter).forEach((key) => {
                    if (/\b(19\d{2}|20\d{2})\b/.test(key)) {
                        shows.push({
                            date: key,
                            min: body.filter[key].min,
                            max: body.filter[key].max,
                        });
                        delete body.filter[key];
                    }
                });
                if (shows.length) {
                    body.filter.shows = shows;
                }
            }
            try {
                let tableType = "default";
                if (trends) {
                    tableType = "trends";
                }
                if (trendsTop100) {
                    tableType = "trendsTop100";
                }
                let response = null;
                switch (tableType) {
                    case "trendsTop100":
                        response = await Vue.api.post(
                            "/service/seller/ext-analitic/get_dynamic_keyword_trend_top",
                            body
                        );
                        break;
                    case "trends":
                        response = await Vue.api.post(
                            "/service/seller/ext-analitic/get_dynamic_keyword_trend",
                            body
                        );
                        break;
                    case "default":
                        response = await Vue.api.post(
                            "/service/seller/ext-analitic/get_dynamic_keyword",
                            body
                        );
                        break;
                }

                const { data } = response;
                const result = {
                    items: [...data.data],
                    total: +data.totals,
                    success: data.success,
                    message: data.message,
                };
                return result;
            } catch (e) {
                if (
                    e?.response?.data?.errors &&
                    e?.response?.data?.message ===
                        "Tariff plan is not suitable."
                ) {
                    throw new Error(Object.values(e.response.data.errors)[0]);
                } else {
                    throw new Error("no-limit-error");
                }
            }
        },
        async getKeywordDynamicMonths(context, { mp }) {
            const { data } = await Vue.api.post(
                "/service/seller/ext-analitic/get_dynamic_keyword_period_select",
                { marketPlace: mp }
            );
            return data;
        },
    },
};
