var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "help-wrapper"
  }, [_c('div', {
    staticClass: "help activator",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return (() => _vm.popup = !_vm.popup).apply(null, arguments);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@/assets/images/header/help.svg`),
      "alt": "Help",
      "width": "20",
      "height": "20"
    }
  }), _c('span', {
    staticClass: "help-email"
  }, [_vm._v(" Помощь ")])]), _c('slide-y-up-transition', [_vm.popup ? _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: () => _vm.popup = false,
      expression: "() => (popup = false)"
    }],
    staticClass: "help-popup"
  }, [_c('div', [_c('ul', {
    staticClass: "nav"
  }, [_c('li', [_c('a', {
    attrs: {
      "href": "https://help.salesfinder.ru/"
    },
    on: {
      "click": () => _vm.popup = false
    }
  }, [_vm._v(" Обучающие материалы ")])]), _c('li', [!_vm.$store.state.user.user.external_service_id ? _c('a', {
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": _vm.openCarrotquestChat
    }
  }, [_vm._v(" Связаться с поддержкой ")]) : _vm._e()])])])]) : _vm._e()])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };