import "core-js/modules/es.array.push.js";
import LoadingLayout from "./layouts/LoadingLayout.vue";
import DefaultLayout from "./layouts/DefaultLayout.vue";
import EmptyLayout from "./layouts/EmptyLayout.vue";
export default {
  components: {
    DefaultLayout,
    EmptyLayout,
    LoadingLayout
  },
  meta: {
    titleTemplate: titleChunk => {
      let T = process.env.VUE_APP_TITLE || "SalesFinder";
      return titleChunk ? `${T} - ${titleChunk}` : T;
    }
  },
  data() {
    return {
      modalsIncrement: 0,
      modals: []
    };
  },
  created() {
    const {
      user
    } = this.$store.state.user;
    if (user) {
      if (user.external_service_id) {
        window.carrotquest.removeChat();
      }
    }
    this.fetchTestUsers();
    const generateModalOpener = method => async (componentArg, options) => {
      const component = (await componentArg()).default;
      const id = this.modalsIncrement++;
      this.modals.push({
        component,
        id
      });
      await this.$nextTick();
      if (this.$refs[`modal-${id}`]) {
        return this.$refs[`modal-${id}`][0][method](options);
      }
    };
    this.$modal.onOpen = generateModalOpener("open");
    this.$modal.onProcess = generateModalOpener("process");
    if (this.$store.state.user.outOfLimit && this.$route.name !== "AddTGBot") {
      //console.log('64: this.$store.state.user=',this.$store.state.user)

      this.$modal.open(() => import("@/components/modals/access/ModalOutOfLimitLogout"), {
        limit: this.$store.state.user.outOfLimit
      });
    }
  },
  methods: {
    async fetchTestUsers() {
      try {
        const response = await fetch("/test-users.json");
        const json = await response.json();
        this.$store.dispatch("setTestUsers", json.users);
      } catch (error) {
        console.error("Failed to fetch test users:", error);
        return {};
      }
    }
  },
  watch: {
    $route() {
      this.modals = [];
    }
  },
  computed: {
    layout() {
      if (!this.$route.name) {
        return "loading-layout";
      }
      return this.$route.meta.layout || "default-layout";
    }
  }
};