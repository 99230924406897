import Vue from "vue";
import { mapFilters } from "@/utils/newModule";

export default {
    namespaced: true,
    actions: {
        async getProduct(context, { p_id, mp, date, date2, fbs }) {
            const { data } = await Vue.api.get("/getProduct", {
                params: {
                    mp,
                    p_id,
                    date,
                    date2,
                    fbs,
                },
            });
            return data;
        },

        async getProductAuth(context, { p_id, mp, date, date2, fbs }) {
            const { data } = await Vue.api.get("/getProductAuth", {
                params: {
                    mp,
                    p_id,
                    date,
                    date2,
                    fbs,
                },
            });
            return data;
        },
        async getProductBot(context, { p_id, mp, date, date2, fbs }) {
            const { data } = await Vue.api.get("/getProductBot", {
                params: {
                    mp,
                    p_id,
                    date,
                    date2,
                    fbs,
                },
            });
            return data;
        },

        async getProductByName(context, { query, mp }) {
            const { data } = await Vue.api.get("/getProductByName", {
                params: {
                    query,
                    mp,
                },
            });
            return data;
        },

        async productOverviewAll(context, queryParams) {
            const { data } = await Vue.api.get("/productOverviewAll", {
                params: {
                    ...queryParams,
                },
            });
            return data;
        },

        /*
        async productOverviewAll(context, { mp, p_id, date, date2, fbs }) {
            const { data } = await Vue.api.get("/productOverviewAll", {
                params: {
                    mp,
                    p_id,
                    date,
                    date2,
                    fbs,
                    // like _CH_DROP_CACHE_
                    ...queryParams,
                },
            });
            return data;
        },
        */

        async getProductDays(context, queryParams) {
            const { mp, p_id, date, date2 } = queryParams;
            const { data } = await Vue.api.get("/getProductDays", {
                params: {
                    mp,
                    p_id,
                    date,
                    date2,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },
        async getProductCategoriesPositions(context, queryParams) {
            const { mp, p_id, date, date2 } = queryParams;
            const { data } = await Vue.api.get(
                "/getProductCategoriesPositions",
                {
                    params: {
                        mp,
                        p_id,
                        date,
                        date2,
                        // other params like pagination, sorting, filtering is dynamic
                        ...queryParams,
                    },
                }
            );
            return data;
        },
        //kwd
        async getProductKeywordsPositions(context, queryParams) {
            const { mp, p_id, date, date2 } = queryParams;
            const { data } = await Vue.api.get("/getProductKeywordsPositions", {
                params: {
                    mp,
                    p_id,
                    date,
                    date2,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },
        async getProductSizes(context, queryParams) {
            const { mp, p_id, date, date2 } = queryParams;
            const { data } = await Vue.api.get("/getProductSizes", {
                params: {
                    mp,
                    p_id,
                    date,
                    date2,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },
        async getProductStores(context, queryParams) {
            const { mp, p_id, date, date2 } = queryParams;
            const { data } = await Vue.api.get("/getProductStores", {
                params: {
                    mp,
                    p_id,
                    date,
                    date2,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },


        //sw->sf
        async productAdvYandex(context, queryParams) {
            const { mp, p_id, date, date2 } = queryParams;
            const { data } = await Vue.api.get("/productAdvYandex", {
                params: {
                    mp,
                    p_id,
                    date,
                    date2,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },
        async productSeoYandex(context, queryParams) {
            const { mp, p_id, date, date2 } = queryParams;
            const { data } = await Vue.api.get("/productSeoYandex", {
                params: {
                    mp,
                    p_id,
                    date,
                    date2,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },
        async productSeoGoogle(context, queryParams) {
            const { mp, p_id, date, date2 } = queryParams;
            const { data } = await Vue.api.get("/productSeoGoogle", {
                params: {
                    mp,
                    p_id,
                    date,
                    date2,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },


        async getCompareTopSku(context, { mp, category }) {
            const { data } = await Vue.api.post(
                "/service/seller/ext-analitic/get_compare_top_sku",
                { marketPlace: mp, category }
            );
            return data;
        },
        async getCompareProducts(context, { mp, date, date2, fbs, sku }) {
            try {
                const { data } = await Vue.api.post(
                    "/service/seller/ext-analitic/get_compare_product",
                    {
                        marketPlace: mp,
                        dateFrom: date,
                        dateTo: date2,
                        fbs: !!fbs,
                        sku,
                    }
                );
                const result = {
                    items: [...data.data.items],
                    total: +data.totals,
                    success: data.success,
                    message: data.message,
                };
                return result;
            } catch (e) {
                if (
                    e?.response?.data?.errors &&
                    e?.response?.data?.message ===
                        "Tariff plan is not suitable."
                ) {
                    throw new Error(Object.values(e.response.data.errors)[0]);
                } else {
                    throw new Error("no-limit-error");
                }
            }
        },
        async getCompareCategories(
            context,
            {
                page = 1,
                page_size = 20,
                skip = 0,
                mp,
                date,
                date2,
                fbs,
                sku,
                filters,
                sort,
                sort_dir,
            }
        ) {
            skip = (page - 1) * page_size;
            const body = {
                marketPlace: mp,
                dateFrom: date,
                dateTo: date2,
                filter: filters,
                fbs: !!fbs,
                sku,
                take: page_size,
                pageSize: page_size,
                page,
                skip,
            };
            if (sort) {
                body.sort = sort;
            }
            if (sort_dir) {
                body.sort_dir = sort_dir;
            }
            if (body.filter) {
                body.filter = mapFilters(body.filter);
            }
            const { data } = await Vue.api.post(
                "/service/seller/ext-analitic/get_compare_category_product",
                body
            );
            const result = {
                items: data.data.items?.length ? [...data.data.items] : [],
                categories: data.data.categories?.length
                    ? [...data.data.categories]
                    : [],
                total: +data.totals,
                success: data.success,
                message: data.message,
            };
            return result;
        },
        async getCompareKeywords(
            context,
            {
                page = 1,
                page_size = 20,
                skip = 0,
                mp,
                date,
                date2,
                fbs,
                sku,
                filters,
                sort,
                sort_dir,
            }
        ) {
            skip = (page - 1) * page_size;
            const body = {
                marketPlace: mp,
                dateFrom: date,
                dateTo: date2,
                filter: filters,
                fbs: !!fbs,
                sku,
                take: page_size,
                pageSize: page_size,
                page,
                skip,
            };
            if (sort) {
                body.sort = sort;
            }
            if (sort_dir) {
                body.sort_dir = sort_dir;
            }
            if (body.filter) {
                body.filter = mapFilters(body.filter);
            }
            const { data } = await Vue.api.post(
                "/service/seller/ext-analitic/get_compare_keyword_position",
                body
            );
            const result = {
                items: data.data.items?.length ? [...data.data.items] : [],
                total: +data.totals,
                success: data.success,
                message: data.message,
            };
            return result;
        },
    },
};
